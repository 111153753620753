import Logger from 'components/Common/Logger';
import fileDownload from 'js-file-download';
import http, { httpRaw } from 'utils/http';
import store from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { DEMO_DATA, DOWNLOAD } from 'redux/actions/Types';
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { auth } from 'utils/urlConstants';
import { buildQueryString } from 'utils/jsUtils';
import { showAlert } from 'redux/actions/AlertActions';


const exportTokenExpiry = 285000; // (60sec * 4min + 45sec) * 1000ms

function* getExportToken(orgId) {
  const localValue = store.getAsJson(CACHE.exportToken);
  if (localValue && !store.isTokenExpired(localValue.ttl)) {
    return localValue.token;
  }
  else {
    const { exportToken, error, message } = yield call(http.get, auth.exportToken(orgId));
    if (error) {
      Logger.error(error, `Failed to generate export token: ${message}`);
      return null;
    }
    const ttl = Date.now() + exportTokenExpiry;
    store.set(CACHE.exportToken, JSON.stringify({ token: exportToken, ttl }));
    return exportToken;
  }
}

function* downloadCsv(action) {
  const token = yield call(getExportToken, action.orgId);
  if (!token) { return false; }
  const exportUrl = `${action.url}${buildQueryString({ token }, action.url)}`;
  return window.open(exportUrl);
}

function* downloadCsvPostCall(action) {
  try {
    const token = yield call(getExportToken, action.orgId);
    if (!token) { return false; }

    const response = yield call(httpRaw.post, action.url, { ...action.payload, token });
    const data = yield response.text();
    fileDownload(data, action.fileName);

    yield put({ type: DOWNLOAD.DOWNLOAD_CSV_SUCCESS });
  }
  catch (e) {
    yield put({ type: DOWNLOAD.DOWNLOAD_CSV_ERROR });
    yield put(showAlert({ message: 'CSV export failed', type: 'error' }));
  }
  return true;
}

function* downloadDemoErrorReport(action) {
  try {
    const token = yield call(getExportToken, action.orgId);
    if (!token) { return false; }
    const exportUrl = `${action.url}${buildQueryString({ token }, action.url)}`;
    return window.open(exportUrl);
  }
  catch (e) {
    return yield put(showAlert({ message: 'Download failed', type: 'error' }));
  }
}

export default function* root() {
  yield all([
    takeEvery(DOWNLOAD.DOWNLOAD_CSV, downloadCsv),
    takeEvery(DOWNLOAD.DOWNLOAD_CSV_POST, downloadCsvPostCall),
    takeEvery(DEMO_DATA.DOWNLOAD_ERROR_FILE, downloadDemoErrorReport),
  ]);
}

export {
  downloadCsv,
  getExportToken,
};
