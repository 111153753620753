import {
  ActivationNode,
  IdentityNode,
  QuestionNode,
  ScheduleNode,
  TransformationNode,
} from 'pages/Flow/Nodes';
import { NODES_TYPE } from 'pages/Flow/constants';


export const nodeTypes = {
  [NODES_TYPE.question]: QuestionNode,
  [NODES_TYPE.transformation]: TransformationNode,
  [NODES_TYPE.activation]: ActivationNode,
  [NODES_TYPE.indentity]: IdentityNode,
  [NODES_TYPE.scheduling]: ScheduleNode,
};
