import Grid from '@mui/material/Grid';
import React, { memo } from 'react';
import { FlowNode, FlowNodeBody, FlowNodeHeader } from 'pages/Flow/Nodes/FlowNode';
import { Handle } from 'reactflow';
import { NODES_OBJECT, NODES_TYPE } from 'pages/Flow/constants';
import { useActionMenuItems } from 'pages/Flow/Nodes/ScheduleNode/useActionMenuItems';


export const ScheduleNode = memo(({ data, selected, ...rest }) => {
  const scheduleNodeAction = useActionMenuItems();

  return (
    <FlowNode type={NODES_TYPE.scheduling} selected={selected}>
      <Handle type='target' position='left' />
      <FlowNodeHeader
        text={rest.id}
        Icon={NODES_OBJECT[NODES_TYPE.scheduling].Icon}
        menuItems={scheduleNodeAction}
      />
      <FlowNodeBody>
        <Grid item xs={12}>
          {rest.id}
        </Grid>
        <Grid item>
          {data.label}
        </Grid>
      </FlowNodeBody>
      <Handle type='source' position='right' />
    </FlowNode>
  );
});
