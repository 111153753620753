export const hasNodeListBeenModified = (initialNodeList, latestNodeList) => latestNodeList.filter(
  latestNode => {
    const currentNode = initialNodeList.find(node => node.ID === latestNode.id);

    if (!currentNode) {
      // Object is not present in the initialNodeList, so it was added
      return true;
    }

    const isPositionXModified = latestNode.position.x !== currentNode.positionX;
    const isPositionYModified = latestNode.position.y !== currentNode.positionY;
    const isReferenceIDModified = latestNode.data.referenceID !== currentNode.referenceID;
    const isTypeModified = latestNode.type !== currentNode.nodeType;
    const isLabelModified = latestNode.data.label !== currentNode.label;

    return isPositionXModified || isPositionYModified || isReferenceIDModified
    || isTypeModified || isLabelModified;
  },
);

export const isValidDeltaExponential = (value) => {
  if (!value) {
    return false;
  }

  const pattern = /^(\d*\.?\d+)E([-+]?\d+)$/;
  const match = value.match(pattern);

  if (!match) {
    return false;
  }

  const base = parseFloat(match[1]);
  const exponent = parseInt(match[2], 10);

  const number = base * 10 ** exponent;
  const lowerBound = 1e-10;
  const upperBound = 1e-5;
  const epsilon = 1e-15; // Small margin of error

  // Ensure comparison accounts for floating-point precision
  return number >= lowerBound - epsilon && number <= upperBound + epsilon;
};

export const getDeltaString = (param) => {
  const defaultValue = '1.4E-10';

  if (isValidDeltaExponential(param)) {
    return param;
  }
  else {
    return defaultValue;
  }
};
