import moment from 'moment';


export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const CACHE = {
  defaultLayout: 'defaultLayout',
  expandedMenuItems: 'expandedMenuItems',
  expiry: 8, // in hours
  exportToken: 'exportToken',
  habuHelpDocsReturnToURL: 'habuHelpDocsReturnToURL',
  deepLinkSearch: 'deepLinkSearch',
  inboundInvitations: 'inboundInvitations',
  impersonation: {
    impersonator: 'impersonator',
    refreshToken: 'impersonatorsRefreshToken',
  },
  landingPage: 'landingPage',
  sessionId: 'sessionId',
  sideNavLayout: 'sideNavLayout',
  themeType: 'themeType',
  user: {
    jwtAccessToken: 'jwtAccessToken',
    jwtRefreshToken: 'jwtRefreshToken',
    id: 'id',
    primaryAccount: 'primaryAccount',
    primaryOrg: 'primaryOrg',
    userDetails: 'userDetails',
    isDemoAccount: 'isDemoAccount',
  },
};

export const cardsPerPageOptions = [30, 60, 100];

export const consentConstants = {
  jobLevel: 'Job Level',
  orgLevel: 'Organization Level',
};

export const DROPDOWN_NONE_VALUE = '-';

export const filterOptions = [
  {
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    title: 'Trailing 7 Days',
  },
  {
    startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    title: 'Trailing 14 Days',
  },
  {
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    title: 'Trailing 30 Days',
  },
];

export const ITEM_PER_PAGE_OPTIONS = [24, 48, 96];

export const SORT_DIRECTION = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const habuSupport = {
  email: 'help@habu.com',
  message: 'Please contact us at help@habu.com if this persists',
};

export const orgTypes = {
  Standard: 'standard',
  SSO: 'sso',
};

export const PRODUCT = {
  POST_RUN_PROCESSING: {
    name: 'PostRunQueryProcessing',
    display_name: 'Post Run Query Processing',
    description: 'Post Run Query Processing feature toggle to enable for an organization',
  },
  DATA_CONNECTION: {
    name: 'Data Connection',
    displayName: 'Data Connection',
  },
  HABU_INTELLIGENCE_CLONING: {
    name: 'HICloning',
    displayName: 'Intelligence Cloning',
  },
};

export const productPerm = {
  alerts: 'Alerts',
  cleanCompute: 'CleanCompute',
  crAdmin: 'CleanRoomsAdmin',
  crPartnerReadOnly: 'CleanRoomsPartnerReadOnly',
  crReadOnly: 'CleanRoomsReadOnly',
  crQuestionPermissions: 'CleanRoomQuestionPermissions',
  cdp: 'CDP',
  dataConnectionFieldEncryption: 'DataConnectionFieldEncryption',
  dataInAdmin: 'DataInAdmin',
  dataInUseCases: 'DataInUseCases',
  dataOutAdmin: 'DataOutAdmin',
  dataOutReadOnly: 'DataOutReadOnly',
  ExternalAPIAccess: 'ExternalAPIAccess',
  HabuConfidentialComputing: 'HabuConfidentialComputing',
  habuIntelligenceAdmin: 'HabuIntelligenceAdmin',
  habuIntelligence: 'Habu Intelligence',
  questionBuilderAdmin: 'QuestionBuilderAdmin',
  userListAdmin: 'UserListAdmin',
  questionManagementAdmin: 'QuestionManagementAdmin',
  GPGEncryption: 'GPGEncryption',
  databricksSQLCleanroom: 'DatabricksSQLCleanroom',
  ActivationChannels: 'ActivationChannels',
  googlePAIR: 'PAIR',
  OPJA: 'OPJA',
  Snowpark: 'Snowpark',
  cleanRoomActivation: 'CleanRoomActivation',
  Flow: 'Flow',
  snowflakeResultShare: 'SnowflakeResultShare',
  DisableQuestionReportBuilder: 'DisableQuestionReportBuilder',
  QuestionBuilderV3: 'QuestionBuilder3.0',
  QuestionPurpose: 'QuestionPurpose',
  AIQueryHelper: 'AIQueryHelper',
  AIQuestionDescription: 'AIQuestionDescription',
  CleanComputeQuestion: 'CleanComputeQuestion',
  DifferentialPrivacy: 'DifferentialPrivacy',
  AIHabuAssistant: 'AIHabuAssistant',
  DatasetAnalysisRule: 'DatasetAnalysisRule',
  CapacityManagement: 'CapacityManagement',
  CapacityManagementSnowflakePattern: 'CapacityManagementSnowflakePattern',
  EncryptedListExport: 'EncryptedListExport',
  MultiRegionCleanRooms: 'MultiRegionCleanRooms',
  CleanRoomPermissionEnhancement: 'CleanRoomPermissionEnhancement',
  LiveRampActivation: 'LiveRampActivation',
  DatasetAssignmentDelegation: 'DatasetAssignmentDelegation',
  DataExportsV2: 'DataExports2.0',
  Transcoding: 'Transcoding',
  UserDefinedFunctionSupport: 'UserDefinedFunctionSupport',
  AMCListQuestion: 'AMCListQuestion',
  CleanComputeOnSparkQuestion: 'CleanComputeOnSparkQuestion',
  EnableTrustCenter: 'EnableTrustCenter',
  UserTrustCenterAdmin: 'UserTrustCenterAdmin',
  UserTrustCenterReadOnly: 'UserTrustCenterReadOnly',
  CleanRoomTaxonomy: 'CleanRoomTaxonomy',
  NewKminThreshold: 'NewKminThreshold',
  FlowMacros: 'FlowMacros',
  FlowReusableRuns: 'FlowReusableRuns',
  QuestionMultipleOutputs: 'QuestionMultipleOutputs',
  FederatedQuery: 'FederatedQuery',
  questionVersioningV1: 'QuestionVersioningV1',
  CrRunSummary: 'CleanRoomRunSummary',
  DatasetMaterialization: 'DatasetMaterialization',
  IdentityResolution: 'IdentityResolution',
  SyntheticDataConnections: 'SyntheticDataConnections',
  DisableIdentifierProjection: 'DisableIdentifierProjection',
  cleanRoomActivationV1: 'CleanRoomActivationV1',
};

export const cleanRoomPermissions = {
  viewReports: 'CR-ViewReports',
  manageReports: 'CR-ManageReports',
  scheduleRuns: 'CR-ScheduleRuns',
  cleanRoomManagement: 'CR-CleanRoomManagement',
  partnerManagement: 'CR-PartnerManagement',
  activation: 'CR-Activation',
  manageDatasets: 'CR-ManageDatasets',
  habuIntelligenceRead: 'CR-HabuIntelligenceRead',
  habuIntelligenceWrite: 'CR-HabuIntelligenceWrite',
  questionBuilder: 'CR-QuestionBuilder',
  manageQuestions: 'CR-ManageQuestions',
  viewUserLists: 'CR-ViewUserLists',
  manageUserLists: 'CR-ManageUserLists',
  crFlowsBuilder: 'CR-FlowsBuilder',
};

export const rowsPerPageOptions = [10, 30, 50];

export const sortDirections = { asc: 'asc', desc: 'desc' };

export const trademark = `©${(new Date()).getFullYear()} Habu Inc.`;

export const UI_DATE_FORMAT = 'DD MMM. YYYY';

export const US_DATE_FORMAT = 'MM/DD/YYYY';

export const US_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';

export const UI_DISPLAY_DATE_TIME_FORMAT = 'DD MMM. YYYY  hh:mm A';

export const UI_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm';

export const SCHEDULE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const TIMESTAMP_DATE_TIME_FORMAT = 'YYYY-MM-DD, hh:mm:ss A';

export const UI_SHORT_DATE = 'DD MMM';

export const userTypes = {
  accountAdmin: 'AccountAdmin',
  superuser: 'SuperUser',
  consoleUser: 'ConsoleUser',
};

// Keys used by Moonraker to find the path of documentation and redirect the user to it
export const HELP_DOC_PATH_MAP = {
  cleanRoomCustomization: 'clean_room_customization',
  cleanRoomInviteFlow: 'cr_partner_invite',
  cleanRoomNavGuide: 'crq_nav_guide',
  installSnowflakeCliMac: 'install_snowflake_cli_mac',
  installSnowflakeCliWindows: 'install_snowflake_cli_windows',
  navigation: 'navigation',
  questionManagement: 'question_management',
  questionBuilder: 'question_builder',
  snowFlakeAccountInfo: 'sf_account_info',
  updateSnowflakeCliVersion: 'update_snowflake_cli_version',
  installDatabricksCli: 'install_databricks_cli',
};

export const DEFAULT_AUTO_REFRESH_INTERVAL = 30000; // 30 seconds

export const SESSION_STORAGE_KEYS = {
  // Below keys are used in Question Builder
  QB_CR_ID: 'QB_CR_ID',
  QB_CR_OWNERSHIP: 'QB_CR_OWNERSHIP',
  QB_CR_TYPE: 'QB_CR_TYPE',
};

export const fileUploadStatus = {
  success: 'success',
  mismatch: 'mismatch',
  uploading: 'uploading',
  error: 'error',
};

export const HABU_ENVIRONMENT = 'production';

export const userPermissions = {
  dataImportJobsReadOnly: 'DataImportJobsReadOnly',
  dataImportJobsAdmin: 'DataImportJobsAdmin',
  userListReadOnly: 'UserListReadOnly',
  orgAdmin: 'OrgAdmin',
  habuManagedSnowflakeAccount: 'HabuManagedSnowflakeAccount',
  ...userTypes,
  ...productPerm,
  ...cleanRoomPermissions,
};

export const REPORT_LIMIT = 1000;
