import React from 'react';
import { FlowItem } from 'pages/Flow/FlowSideBar/FlowItem';
import { NODES_OBJECT } from 'pages/Flow/constants';
import { StyledAside } from 'pages/Flow/FlowSideBar/styles';


const itemNodeList = Object.keys(NODES_OBJECT).reduce((result, curr) => {
  if (NODES_OBJECT[curr].active) {
    result.push({
      Icon: NODES_OBJECT[curr].Icon,
      type: curr,
    });
  }
  return result;
}, []);

export const FlowSideBar = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <StyledAside>
      {itemNodeList.map(({ type, Icon }) => (
        <FlowItem key={type} onDragStart={onDragStart} type={type}>
          {Icon}
        </FlowItem>
      ))}
    </StyledAside>
  );
};
