import { FLOW_STATUS } from 'utils/ignoramusEnums';
import { initialFlows } from 'pages/Flow/MockData/flowData';


export const DEFAULT_START_LEVEL_VALUE = 'Begin at *';

export const DEFAULT_PAUSE_LEVEL_VALUE = 'End at (optional)';

export const initialState = {
  summaries: [],
  summariesLoading: false,
  listOfFlows: initialFlows,
  nodes: [],
  edges: [],
  flowsCache: initialFlows,
  flow: {
    ID: '',
    title: '',
    description: '',
    tags: [],
    stage: FLOW_STATUS.MISSING_DATASETS.key,
  },
  nodesEdgeConnectionDialog: {
    open: false,
  },
  nodeSize: 'MD',
  selectedStartLevel: DEFAULT_START_LEVEL_VALUE,
  selectedPauseLevel: DEFAULT_PAUSE_LEVEL_VALUE,
};
