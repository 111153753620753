import * as actions from 'redux/actions/DataInActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { DATA_IN } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { dataIn } from 'utils/urlConstants';
import { habuSupport } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';


function* saveJob(action) {
  // Creating a data in job call
  const saveErrors = [];
  const responsePayload = {};

  const { error, job } = yield call(
    action.jobId ? http.put : http.post,
    action.jobUrl,
    { job: action.jobPayload },
  );

  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(`Could not save data in job. Url:${action.jobUrl}. Error: ${error}`,
      'Data Connections: Save Data Connections Job');
    return yield put(actions.jobSaveFailure(error));
  }
  else {
    responsePayload.job = job;
  }

  const paramUrl = dataIn(action.orgId).updateJobParameters(job.importDataSource.id, job.id);
  const parametersPayload = action.parameters.map(p => ({ ...p, importJobID: job.id }));
  const jobParameters = yield call(http.post, paramUrl, { parameters: parametersPayload });
  if (jobParameters.error) {
    Logger.error(`Could not save job parameters. Url:${paramUrl}. Error: ${jobParameters.error}`,
      'Data Connections: Save Data Connections Job Parameters');
    saveErrors.push(jobParameters.error);
  }
  else {
    responsePayload.parameters = jobParameters.parameters;
  }

  if (action.consentPayload?.consentType) {
    const consentUrl = dataIn(action.orgId).createJobConsent(job.id);
    const consentResponse = yield call(http.post, consentUrl, { consent: action.consentPayload });
    if (consentResponse.error) {
      Logger.error(`Could not save job consent. Url:${consentUrl}. Error: ${consentResponse.error}`,
        'Data Connections: Save Data Connections Job Consent');
      saveErrors.push(consentResponse.error);
    }
    else {
      responsePayload.consent = consentResponse.consent;
    }
  }

  if (saveErrors.length > 0) {
    yield put(showAlert({ message: saveErrors.join('\n'), type: 'error' }));
    return yield put(actions.jobSaveFailure(saveErrors.join('\n')));
  }
  else {
    yield put(showAlert({ message: 'Job created', type: 'success' }));
    return yield put(actions.jobSaveSuccess(responsePayload));
  }
}

function* fetchDataInDetails(action) {
  const { job, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataInDetailsError(error));
  }
  return yield put(actions.fetchDataInDetailsSuccess(job));
}

function* fetchDataInFilters(action) {
  const { sorts, filters, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Data Connections Filters. Url: ${action.url}, Error: ${error}`,
      'DATA_IN: Fetch Data Connections Filters');
    return yield put(actions.fetchDataInFiltersError(error));
  }
  return yield put(actions.fetchDataInFiltersSuccess({ sorts, filters }));
}

function* fetchDataInJobSummary(action) {
  const { job, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataInJobSummaryError(error));
  }
  return yield put(actions.fetchDataInJobSummarySuccess(job));
}

function* fetchDataInJobs(action) {
  const { jobs, error } = yield call(http.post, action.url, {
    ...action.payload,
    client: 'topgallant',
  });
  if (error) {
    return yield put(actions.fetchDataInJobsError(error));
  }
  return yield put(actions.fetchDataInJobsSuccess(jobs));
}

function* changeJobStatus(action) {
  const { error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Data Connections: Change Job Status');
    return yield put(actions.changeJobStatusError(
      `Couldn't update status of Job: ${action.meta.name}. ${habuSupport.message}`,
    ));
  }
  return null;
}

function* changeJobStage(action) {
  const { error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Data Connections: Change Job Stage');
    return yield put(actions.changeJobStageError(
      `Couldn't update stage of Job: ${action.meta.name}. ${habuSupport.message}`,
    ));
  }
  return null;
}

function* fetchDataInJobTrend(action) {
  const { jobTrend, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataInJobTrendError(error));
  }
  return yield put(actions.fetchDataInJobTrendSuccess(jobTrend));
}

function* fetchDataInPeopleHouseholds(action) {
  const { peopleHhTrend, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataInPeopleHouseholdsError(error));
  }
  return yield put(actions.fetchDataInPeopleHouseholdsSuccess(peopleHhTrend));
}

function* fetchDataSource(action) {
  const { dataSource, error } = yield call(http.get, action.url);
  if (error) {
    return yield put(actions.fetchDataSourceFailure(error));
  }
  return yield put(actions.fetchDataSourceSuccess(dataSource));
}

function* fetchDataSourceJobs(action) {
  const { dataSources, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataSourceJobsError(error));
  }
  return yield put(actions.fetchDataSourceJobsSuccess(dataSources));
}

function* fetchDataSources(action) {
  const { dataSources, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveDataSourcesError(error));
  }
  return yield put(actions.receiveDataSourcesSuccess(dataSources));
}

function* fetchDataSourceParameters(action) {
  const { parameters, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Data Source Parameters. Url: ${action.url}. Error: ${error}`,
      'Data Connections : Fetch Data Source Parameters');
    yield put(showAlert({ message: `${error}. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchDataSourceParametersFailure(error));
  }
  return yield put(actions.fetchDataSourceParametersSuccess(parameters));
}

function* fetchDataSourceDependentParameters(action) {
  const { dependentParameters, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Data Source Dependent Parameters. Url: ${action.url}. Error: ${error}`,
      'Data Connections : Fetch Data Source Dependent Parameters');
    yield put(showAlert({ message: `${error}. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchDataSourceDependentParametersFailure(error));
  }
  return yield put(actions.fetchDataSourceDependentParametersSuccess({
    dependentParameters,
    ...action,
  }));
}

function* fetchJobParameters(action) {
  const { parameters, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch job parameters. Url: ${action.url}. Error: ${error}`,
      'Data Connections: Select Data Source Error');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchJobParametersFailure(error));
  }
  return yield put(actions.fetchJobParametersSuccess(parameters));
}

function* fetchDataSetTypeList(action) {
  const { dataTypes } = yield call(http.get, action.url);
  yield put(actions.receiveDataSetTypeList(dataTypes));
}

function* fetchDeviceTypeList(action) {
  const { deviceTypes } = yield call(http.get, action.url);
  yield put(actions.receiveDeviceTypeList(deviceTypes));
}

function* updateJobParameters(action) {
  const { error, parameters } = yield call(http.post, action.url, { parameters: action.payload });
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateJobParametersFailure(error));
  }
  yield put(showAlert({ message: 'Job Parameters submitted', type: 'success' }));
  return yield put(actions.updateJobParametersSuccess(parameters));
}

function* submitDataInFieldMapping(action) {
  const { fieldConfigurations, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(`Could not submit field mapping. Url: ${action.url}. Error: ${error}`,
      'DATA_IN: Submit Data Connections Field Mapping');
    return yield put(actions.submitDataInJobFieldMappingError(error));
  }
  yield put(showAlert({ message: 'Field mapping saved', type: 'success' }));
  return yield put(actions.submitDataInJobFieldMappingSuccess(fieldConfigurations));
}

function* fetchAttributeCategoryList(action) {
  const { attributeCategories } = yield call(http.get, action.url);
  yield put(actions.receiveAttributeCategoryList(attributeCategories));
}

function* fetchFieldMappingList(action) {
  const { fieldConfigurations, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Data Connections: Fetching Field Mapping');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchFieldMappingListFailure(error));
  }
  else {
    return yield put(actions.fetchFieldMappingListSuccess(fieldConfigurations));
  }
}

function* fetchJobConsentList(action) {
  const { consent, error } = yield call(http.get, action.url);
  if (!error) {
    yield put(actions.receiveDataInJobConsent(consent));
  }
}

function* submitDataInJobConsent(action) {
  const { consent, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.submitDataInJobConsentError(error));
  }
  yield put(showAlert({ message: 'Job Consent Created', type: 'success' }));
  return yield put(actions.submitDataInJobConsentSuccess(consent));
}

function* submitJobStatus(action) {
  const data = yield call(http.post, action.url, action.payload);
  if (data.error) {
    yield put(showAlert({ message: `Error: ${data.error}`, type: 'error' }));
    return yield put(actions.submitJobStatusError(data));
  }
  yield put(showAlert({ message: 'Data Connections Job status updated', type: 'success' }));
  return yield put(actions.submitJobStatusSuccess(data.success));
}

function* fetchDataInJobsSummary(action) {
  const { summary, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataInJobsSummaryError(error));
  }
  else {
    return yield put(actions.fetchDataInJobsSummarySuccess(summary));
  }
}

function* fetchDataInJobStats(action) {
  const { importJobRuns, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Data Connections: Fetch Data Connections Job Stats');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchDataInJobStatsError(error));
  }
  else {
    return yield put(actions.fetchDataInJobStatsSuccess(importJobRuns));
  }
}

export default function* root() {
  yield all([
    takeLatest(DATA_IN.CREATE_DATA_IN_JOB_CONSENT, submitDataInJobConsent),
    takeLatest(DATA_IN.JOB_SAVE, saveJob),
    takeLatest(DATA_IN.DATA_IN_CHANGE_JOB_STATUS, changeJobStatus),
    takeLatest(DATA_IN.FETCH_ATTRIBUTE_CATEGORY_LIST, fetchAttributeCategoryList),
    takeLatest(DATA_IN.FETCH_DATA_IN_DETAILS, fetchDataInDetails),
    takeLatest(DATA_IN.FETCH_DATA_IN_FILTERS, fetchDataInFilters),
    takeLatest(DATA_IN.FETCH_DATA_IN_JOB_SUMMARY, fetchDataInJobSummary),
    takeLatest(DATA_IN.FETCH_DATA_IN_JOBS, fetchDataInJobs),
    takeLatest(DATA_IN.FETCH_DATA_IN_JOBS_SUMMARY, fetchDataInJobsSummary),
    takeLatest(DATA_IN.FETCH_DATA_IN_JOB_CONSENT, fetchJobConsentList),
    takeLatest(DATA_IN.FETCH_DATA_IN_JOB_STATS, fetchDataInJobStats),
    takeLatest(DATA_IN.FETCH_DATA_IN_JOB_TREND, fetchDataInJobTrend),
    takeLatest(DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS, fetchDataInPeopleHouseholds),
    takeLatest(DATA_IN.FETCH_DATA_SET_TYPE_LIST, fetchDataSetTypeList),
    takeLatest(DATA_IN.FETCH_DATA_SOURCE, fetchDataSource),
    takeLatest(DATA_IN.FETCH_DATA_SOURCES, fetchDataSources),
    takeLatest(DATA_IN.FETCH_DATA_SOURCE_JOBS, fetchDataSourceJobs),
    takeLatest(DATA_IN.FETCH_DATA_SOURCE_PARAMETERS, fetchDataSourceParameters),
    takeLatest(DATA_IN.FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS, fetchDataSourceDependentParameters),
    takeLatest(DATA_IN.FETCH_DEVICE_TYPE_LIST, fetchDeviceTypeList),
    takeLatest(DATA_IN.FETCH_FIELD_MAPPING_LIST, fetchFieldMappingList),
    takeLatest(DATA_IN.FETCH_JOB_PARAMETERS, fetchJobParameters),
    takeLatest(DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING, submitDataInFieldMapping),
    takeLatest(DATA_IN.UPDATE_JOB_PARAMETERS, updateJobParameters),
    takeLatest(DATA_IN.UPDATE_JOB_STATUS, submitJobStatus),
    takeLatest(DATA_IN.CHANGE_JOB_STAGE, changeJobStage),
  ]);
}

export {
  changeJobStatus,
  fetchAttributeCategoryList,
  fetchDataInDetails,
  fetchDataInFilters,
  fetchDataInJobs,
  fetchDataInJobsSummary,
  fetchDataInJobStats,
  fetchDataInPeopleHouseholds,
  fetchDataSetTypeList,
  fetchDataSources,
  fetchDeviceTypeList,
  fetchFieldMappingList,
  fetchJobConsentList,
  saveJob,
  submitDataInFieldMapping,
  submitDataInJobConsent,
  submitJobStatus,
  updateJobParameters,
};
