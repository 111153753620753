import React, { createContext, memo } from 'react';
import { NODES_TYPE } from 'pages/Flow/constants';


export const FlowNodeContext = createContext({
  selected: false,
  type: NODES_TYPE.disable,
});

export const FlowNodeContextProvider = memo(({ children, value }) => (
  <FlowNodeContext.Provider value={value}>
    {children}
  </FlowNodeContext.Provider>
));
