import { applyNodeChanges } from 'reactflow';


export const updateFlowNodesQuestion = (newNode, initNodesState) => {
  const { data } = newNode;
  const [currentNode = {}] = initNodesState.filter(node => node.id === data.nodeId);

  if (currentNode.data.level && !data.level) {
    data.level = currentNode.data.level;
  }

  currentNode.data = data;

  return applyNodeChanges([currentNode], initNodesState);
};
