import QuestionDetailsNode from 'pages/Flow/Nodes/QuestionNode/QuestionDetailsNode';
import QuestionSetUpNode from 'pages/Flow/Nodes/QuestionNode/QuestionSetUpNode';
import React, { memo } from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { setCreateNode } from 'redux/actions/FlowAction';
import { useDispatch, useSelector } from 'react-redux';



export const QuestionNode = memo(({
  type, xPos, yPos, ...props
}) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const qNodeData = useSelector(state => state.flow.nodes.filter(i => i.id === props.id)[0]);
  const flow = useSelector(state => state.flow.flow);
  const cleanRoom = useSelector(state => state.cleanRooms.detail);

  const onQuestionSelection = (selectedQuestionId) => {
    const nodePayload = {
      node: {
        label: '',
        nodeType: type,
        referenceID: selectedQuestionId,
        positionX: parseInt(xPos, 10),
        positionY: parseInt(yPos, 10),
      },
      cleanRoomID: cleanRoom?.ID || '',
    };

    dispatch(setCreateNode({
      orgId,
      flowID: flow.ID,
      nodeId: props.id,
      nodePayload,
    }));
  };

  if (!qNodeData) {
    return null;
  }

  return (
    <>
      {qNodeData.data.referenceID ? (
        <QuestionDetailsNode
          nodeId={props.id}
          qId={qNodeData.data.referenceID}
          {...props}
        />
      ) : (
        <QuestionSetUpNode onQuestionAdd={onQuestionSelection} {...props} />
      )}
    </>
  );
});
