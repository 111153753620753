
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import FlowNodeIconSmall from 'pages/Flow/Nodes/FlowNode/FlowNodeIconSmall';
import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import {
  FlowNode, FlowNodeBody,
} from 'pages/Flow/Nodes/FlowNode';
import { HHandle } from 'pages/Flow/Nodes/HHandle';
import { NODES_TYPE } from 'pages/Flow/constants';
import { Position } from 'reactflow';
import { flowPalette } from 'pages/Flow/HelpFlow/constants';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';


const StyledIconContainer = styled(Box)(({ theme, type }) => ({
  alignItems: 'center',
  background: flowPalette(theme, type).backgroundColor,
  border: `0.8px solid ${flowPalette(theme, type).borderColor}`,
  borderRadius: '1.8px',
  display: 'flex',
  gap: 9,
  height: 24,
  justifyContent: 'center',
  padding: 4,
  width: 22,
}));


const SmallQuestionNode = ({ question, selected, questionType }) => (
  <FlowNode type={NODES_TYPE.question} selected={selected} size='SM'>
    <HHandle type='target' position={Position.Left} />
    <FlowNodeBody>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs='12'>
          <FlowNodeIconSmall type={NODES_TYPE.question} />
        </Grid>
        <Grid item xs='12'>
          <Box sx={{
            border: `1px solid ${habuColors.neutral[20]}`,
            backgroundColor: habuColors.neutral[10],
            borderRadius: '2px',
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
          }}
          >
            <Typography sx={{ fontSize: '12px' }}>
              {questionType}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs='12' sx={{ textAlign: 'center' }}>
          <Typography
            sx={{ color: selected ? habuColors.secondary[80] : '' }}
            variant='flowNodeTitle'
          >
            {question.title}
          </Typography>
        </Grid>
        <Grid item xs='12'>
          <Typography variant='body3'>{question.displayID}</Typography>
        </Grid>
        <Grid item xs='12'>
          <Badge badgeContent={question.dataTypes.length} color='error'>
            <StyledIconContainer type={NODES_TYPE.dataset}>
              <DatasetOutlinedIcon />
            </StyledIconContainer>
          </Badge>
        </Grid>
      </Grid>
    </FlowNodeBody>
    <HHandle type='source' position={Position.Right} />
  </FlowNode>
);

export default SmallQuestionNode;
