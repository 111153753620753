import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useStartFlowCachedRun = ({ crId, crFlowId, runId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const endpoint = flow(orgId).startCachedFlowRun(crId, crFlowId, runId);
  const {
    isLoading, isSuccess, isError, data, mutate,
  } = useMutation(
    ['start-flow-cached-run', orgId],
    async (payload) => axiosInstance.post(endpoint, payload),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Flow Cached Run have been started.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data, mutate,
  };
};
