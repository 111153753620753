import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Chip from '@mui/material/Chip';
import MacroDescriptionCell from 'pages/Flow/FlowMacro/PreviewMacroTab/MacroDescriptionCell';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { RUN_PARAMETER_TYPES } from 'pages/Flow/constants';


const FlowPreviewMacroTable = ({ flowMacros, onFlowMacroUpdate }) => (
  <TableContainer sx={{ maxHeight: 550 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width='5%' />
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {flowMacros.map((x) => (
          <TableRow key={x.macro.name}>
            <Tooltip
              title={RUN_PARAMETER_TYPES[x.macro.type]?.displayName || 'Unknown'}
              placement='top'
            >
              <TableCell>{RUN_PARAMETER_TYPES[x.macro.type]?.Icon || 'Unknown'}</TableCell>
            </Tooltip>
            <TableCell>
              <Chip
                icon={<AlternateEmailIcon fontSize='small' style={{ marginRight: '8px' }} />}
                label={x.macro.name}
              />
            </TableCell>
            <MacroDescriptionCell
              macro={x.macro}
              description={x.macro.description}
              onFlowMacroUpdate={onFlowMacroUpdate}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default FlowPreviewMacroTable;
