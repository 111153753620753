import React from 'react';
import { flowPalette } from 'pages/Flow/HelpFlow/constants';
import { styled } from '@mui/material/styles';


const StyledDndNode = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => ({
  height: 40,
  minWidth: 40,
  padding: 4,
  borderRadius: 2,
  marginBottom: 10,
  display: 'flex',
  fontWeight: 900,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'grab',
  backgroundColor: `${flowPalette(theme, type).backgroundColor}`,
  border: `1px solid ${flowPalette(theme, type).borderColor}`,
}));

export const FlowItem = ({ children, onDragStart, type }) => (
  <StyledDndNode
    type={type}
    onDragStart={(event) => onDragStart(event, type)}
    draggable
  >
    {children}
  </StyledDndNode>
);
