import 'reactflow/dist/style.css';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import React, { useCallback, useMemo } from 'react';
import ReactFlow, {
  Background, ControlButton, Controls, ReactFlowProvider,
} from 'reactflow';
import { FLOW_VIEW_URL } from 'pages/Flow/constants';
import { FlowSideBar } from 'pages/Flow/FlowSideBar/FlowSideBar';
import {
  checkFlowEdgesConnection,
  nodeSizeChange,
  openSidePanel,
  updateFlowEdges,
  updateFlowNodesAction,
} from 'redux/actions/FlowAction';
import { edgeTypes } from 'pages/Flow/Edges/edgeTypes';
import { nodeTypes } from 'pages/Flow/Nodes/nodeTypes';
import { styled } from '@mui/material/styles';
import { useDeleteEdge } from 'hooks/Flow/edgeAPI';
import { useDeleteNode } from 'api/flows';
import { useDispatch, useSelector } from 'react-redux';
import { useFlow } from 'pages/Flow/useFlow';
import { useLocation } from 'react-router-dom';


const DndStyled = styled('div')({
  flexDirection: 'row',
  display: 'flex',
  flexGrow: 1,
  '& .dndnode.reactflow-wrapper': {
    flexGrow: 1,
    height: '100%',
  },
  '& .dndnode.selectall': {
    marginTop: '10px',
  },
});

const FlowRender = ({ flowId, isViewMode, isCachedRun = false }) => {
  const dispatch = useDispatch();
  const nodeSize = useSelector(state => state.flow.nodeSize);
  const nodes = useSelector(state => state.flow.nodes);
  const edges = useSelector(state => state.flow.edges);
  const { pathname } = useLocation();
  const { mutate: deleteNode } = useDeleteNode(flowId);
  const { mutate: deleteEdge } = useDeleteEdge(flowId);
  const isSelectable = !isCachedRun && (!pathname.includes(FLOW_VIEW_URL) ?? true);
  const isFlowCachedMode = useSelector(state => state.flow.isFlowCachedMode);

  const nodeTypesMemo = useMemo(() => (nodeTypes), []);
  const edgeTypesMemo = useMemo(() => (edgeTypes), []);

  const {
    reactFlowWrapper,
    setReactFlowInstance,
    onDrop,
  } = useFlow();

  const onConnect = useCallback((changes) => {
    dispatch(checkFlowEdgesConnection(changes));
  }, [dispatch]);

  const onNodesChange = useCallback((changes) => {
    dispatch(updateFlowNodesAction(changes));
  }, [dispatch]);

  const onEdgesChange = useCallback((changes) => {
    dispatch(updateFlowEdges(changes));
  }, [dispatch]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onNodesDelete = useCallback((nodesToDelete) => {
    // If a node's ID starts with "Q_", it indicates that this node hasn't been submitted yet.
    // At this point, the node is only local to the flow canvas and hasn't been sent to the backend.
    // We don't need to fire the delete API call; we simply remove it from the local canvas.
    if (nodesToDelete[0].id.startsWith('Q_')) {
      return;
    }

    deleteNode(nodesToDelete[0].id);
  }, [deleteNode]);

  const onEdgesDelete = useCallback((edgesToDelete) => {
    deleteEdge(edgesToDelete[0].id);
  }, [deleteEdge]);


  const onNodeDoubleClick = useCallback((e, node) => {
    // if it is a question node with data, we will open up the side drawer with information
    if (node.type === 'QUESTION' && node.data.ID) {
      dispatch(openSidePanel({ type: 'NODE', data: node }));
    }
  }, [dispatch]);

  const onNodeSizeChange = useCallback(() => {
    const newSize = nodeSize === 'MD' ? 'SM' : 'MD';
    dispatch(nodeSizeChange(newSize));
  }, [dispatch, nodeSize]);

  return (
    <DndStyled>
      <ReactFlowProvider>
        {isSelectable && <FlowSideBar />}
        <div style={{ flexGrow: 1, height: '70vh', overflow: 'hidden' }} ref={reactFlowWrapper}>
          <ReactFlow
            elementsSelectable={isSelectable}
            nodes={nodes}
            edges={edges}
            onNodesDelete={onNodesDelete}
            onNodesChange={onNodesChange}
            onNodeDoubleClick={onNodeDoubleClick}
            onEdgesChange={onEdgesChange}
            onEdgesDelete={onEdgesDelete}
            onConnect={onConnect}
            onInit={(flowInstance) => {
              if (isFlowCachedMode) {
                flowInstance.fitView();
              }
              setReactFlowInstance(flowInstance);
            }}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypesMemo}
            edgeTypes={!isFlowCachedMode ? edgeTypesMemo : []}
            viewPort={{ x: 0, y: 0, zoom: 5 }}
          >
            <Controls showInteractive={!isViewMode && !isCachedRun} position='top-right'>
              {!isCachedRun && (
                <ControlButton onClick={onNodeSizeChange}>
                  <ChangeCircleIcon />
                </ControlButton>
              )}
            </Controls>
            <Background />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </DndStyled>
  );
};

export default FlowRender;
