import React from 'react';
import Toast from 'components/Common/Toast';
import { getAppAlerts } from 'redux/reducer/AlertReducer';
import { hideAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';


const AppAlert = () => {
  const alerts = useSelector(getAppAlerts);
  const dispatch = useDispatch();

  const handleClose = (id) => {
    dispatch(hideAlert(id));
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <Toast
          key={alert.id}
          message={alert.message}
          type={alert.type}
          onClose={() => handleClose(alert.id)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ bottom: `${20 + index * 90}px` }}
        />
      ))}
    </>
  );
};

export default AppAlert;
