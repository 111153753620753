import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useNewCachedFlowRun = ({ crId, runId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    data, isLoading, isSuccess, isError,
  } = useQuery({
    queryKey: ['Fetch-New-Cached-Flow-Run'],
    queryFn: async () => axiosInstance.get(flow(orgId).newCachedFlowRun(crId, runId)),
    onError: (error) => dispatch(
      showAlert({
        message: error.response?.data?.message || 'Error fetching initial data for new cached flow run',
        type: 'error',
      })),
  });

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};
