import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MacroMappingAssignmentWrapper
  from 'pages/Flow/FlowMacro/MacroAssignmentTab/MacroMappingAssignmentWrapper';
import NodeIDHandler from 'pages/Flow/NodeIDHandler';
import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FlowMacroContext } from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroContextProvider';
import { RUN_PARAMETER_TYPES } from 'pages/Flow/constants';


const MacroAssignmentParameterTable = () => {
  const { flowRunParameters, flowMacroMapping } = useContext(FlowMacroContext);
  const returnFlowMacroUsageCount = (macroName) => flowMacroMapping
    .find(({ macro }) => macro.name === macroName)?.runtimeParams?.length || 0;


  const showMacroCount = (runParam) => runParam?.macro !== null
    && Object.keys(runParam.macro).length
    > 0 && flowMacroMapping.length > 0;

  return (
    <Grid item xs={12}>
      {flowRunParameters.map(({ nodeID, nodeRunParameters, nodeQuestionName }) => (
        <Accordion key={nodeID}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${nodeID}-content`}
            id={`flow-macro-assignment-node-${nodeID}`}
          >
            <NodeIDHandler nodeID={nodeID} nodeQuestionName={nodeQuestionName} />
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer sx={{ maxHeight: 450 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width='5%' />
                    <TableCell />
                    <TableCell width='40%' />
                    <TableCell width='5%' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nodeRunParameters.map((runParam) => (
                    <TableRow
                      hover
                      key={`flow-macro-assignment-node-param-${runParam.ID}-${nodeID}-${runParam?.displayName}`}
                    >
                      <Tooltip
                        title={RUN_PARAMETER_TYPES[runParam.type]?.displayName || 'Unknown'}
                        placement='top'
                      >
                        <TableCell>{RUN_PARAMETER_TYPES[runParam.type]?.Icon || 'Unknown'}</TableCell>
                      </Tooltip>
                      <TableCell>
                        <Tooltip
                          title={runParam.displayName}
                          placement='top'
                        >
                          <Typography
                            noWrap
                            style={{
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {runParam.displayName}
                          </Typography>
                        </Tooltip>
                      </TableCell>

                      <MacroMappingAssignmentWrapper
                        nodeID={nodeID}
                        runParameter={runParam}
                      />
                      <TableCell>
                        {showMacroCount(runParam) && (
                          <Box display='flex' alignItems='center'>
                            <Typography variant='body2' sx={{ marginRight: 1 }}>
                              {`${returnFlowMacroUsageCount(runParam?.macro.name)} `}
                            </Typography>
                            <InsertLinkIcon />
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default MacroAssignmentParameterTable;
