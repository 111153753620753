import { NODES_OBJECT, NODES_TYPE } from 'pages/Flow/constants';


export const objectList = [{
  title: 'Question',
  type: NODES_TYPE.question,
  ...NODES_OBJECT[NODES_TYPE.question],
  description: 'Add existing or create new Questions to your flow.',
}, {
  title: 'Models & Code',
  type: NODES_TYPE.scheduling,
  ...NODES_OBJECT[NODES_TYPE.scheduling],
  description: 'Use Clean ML or Clean Compute to add model training or code containers to a Flow.',
}, {
  title: 'Activation',
  type: NODES_TYPE.activation,
  ...NODES_OBJECT[NODES_TYPE.activation],
  description: 'Send user list, send offline conversion, or output data to channel or cloud storage.',
}, {
  title: 'Identity',
  type: NODES_TYPE.indentity,
  ...NODES_OBJECT[NODES_TYPE.indentity],
  description: 'Send user list, send offline conversion, or output data to channel or cloud storage.',
}, {
  title: 'Transformations',
  type: NODES_TYPE.transformation,
  ...NODES_OBJECT[NODES_TYPE.transformation],
  description: 'Use Habu\'s identity resolution partner integrations.',
}];

export const flowPalette = (theme, type) => ({
  [NODES_TYPE.question]: {
    borderColor: theme.palette.flow.question.borderColor,
    backgroundColor: theme.palette.flow.question.backgroundColor,
  },
  [NODES_TYPE.transformation]: {
    borderColor: theme.palette.flow.scheduling.borderColor,
    backgroundColor: theme.palette.flow.scheduling.backgroundColor,
  },
  [NODES_TYPE.activation]: {
    borderColor: theme.palette.flow.activation.borderColor,
    backgroundColor: theme.palette.flow.activation.backgroundColor,
  },
  [NODES_TYPE.indentity]: {
    borderColor: theme.palette.flow.identity.borderColor,
    backgroundColor: theme.palette.flow.identity.backgroundColor,
  },
  [NODES_TYPE.scheduling]: {
    borderColor: theme.palette.flow.transformation.borderColor,
    backgroundColor: theme.palette.flow.transformation.backgroundColor,
  },
  [NODES_TYPE.disable]: {
    borderColor: theme.palette.text.disabled,
    backgroundColor: theme.palette.secondary.light ? theme.palette.secondary.light : theme.palette.grey['200'],
  },
  [NODES_TYPE.dataset]: {
    borderColor: theme.palette.flow.dataset.borderColor,
    backgroundColor: theme.palette.flow.dataset.backgroundColor,
  },
}[type]);
