import { FLOW } from 'redux/actions/Types';
import { flow, question } from 'utils/urlConstants';


export const updateFlowEdges = (payload) => ({
  type: FLOW.UPDATE_FLOW_EDGES,
  payload,
});

export const updateFlowNodesAction = (payload) => ({
  type: FLOW.UPDATE_FLOW_NODES,
  payload,
});

export const addTemporaryNodeToFlow = (payload) => ({
  type: FLOW.ADD_TEMPORARY_NODE_TO_FLOW,
  payload,
});

export const setCreateNode = (payload) => {
  const { orgId, flowID } = payload;

  return {
    type: FLOW.CREATE_NODE,
    url: flow(orgId).createNode(flowID),
    payload,
  };
};

export const setCreateNodeError = (payload) => ({
  type: FLOW.CREATE_NODE_ERROR,
  payload,
});

export const setCreateNodeSuccess = (payload) => ({
  type: FLOW.CREATE_NODE_SUCCESS,
  payload,
});

export const connectNodesWithEdge = (payload) => {
  const { orgId, flowId, ...restPayload } = payload;

  return {
    type: FLOW.CONNECT_NODES_WITH_EDGE,
    url: flow(orgId).createEdge(flowId),
    ...restPayload,
  };
};

export const connectNodesWithEdgeSuccess = (payload) => ({
  type: FLOW.CONNECT_NODES_WITH_EDGE_SUCCESS,
  payload,
});

export const connectNodesWithEdgeError = (payload) => ({
  type: FLOW.CONNECT_NODES_WITH_EDGE_ERROR,
  payload,
});

export const updateFlowNodesQuestionAction = (payload) => {
  const { qId } = payload;
  return {
    type: FLOW.UPDATE_FLOW_NODES_QUESTION,
    url: question().detail(qId),
    payload,
  };
};

export const fetchQuestionDetailSuccess = (payload) => ({
  type: FLOW.UPDATE_FLOW_NODES_QUESTION_SUCCESS,
  payload,
});

export const fetchQuestionDetailError = (payload) => ({
  type: FLOW.UPDATE_FLOW_NODES_QUESTION_ERROR,
  payload,
});

export const openSidePanel = (payload) => ({
  type: FLOW.OPEN_SIDE_PANEL,
  payload,
});

export const editQuestionEdge = (payload) => ({
  type: FLOW.EDIT_QUESTION_EDGE,
  payload,
});

export const closeSidePanel = () => ({
  type: FLOW.CLOSE_SIDE_PANEL,
});

export const checkFlowEdgesConnection = (payload) => ({
  type: FLOW.CHECK_FLOW_EDGES_CONNECTION,
  payload,
});

export const closecheckFlowEdgesConnectionDailog = (payload) => ({
  type: FLOW.CLOSE_CHECK_FLOW_EDGES_CONNECTION,
  payload,
});

export const setFlowNodesSuccess = (payload) => ({
  type: FLOW.FETCH_FLOW_NODE_SUCCESS,
  payload,
});

export const setFlowEdgesSuccess = (payload) => ({
  type: FLOW.FETCH_FLOW_EDGES_SUCCESS,
  payload,
});

export const setFlowDetails = (payload) => {
  const { orgId, crId, crFlowId } = payload;

  return {
    type: FLOW.FETCH_FLOW_DETAILS,
    url: flow(orgId).details(crFlowId, crId),
    payload,
  };
};

export const setFlowDetailsSuccess = (payload) => ({
  type: FLOW.FETCH_FLOW_DETAILS_SUCCESS,
  payload,
});

export const setFlowDetailsError = (payload) => ({
  type: FLOW.FETCH_FLOW_DETAILS_ERROR,
  payload,
});

export const clearFlowStore = () => ({
  type: FLOW.CLEAR_FLOW_CACHE,
});

export const submitFlowSuccess = (payload) => ({
  type: FLOW.SUBMIT_FLOW_SUCCESS,
  payload,
});

export const updateSelectedFlow = (payload) => ({
  type: FLOW.UPDATE_SELECTED_FLOW,
  payload,
});

export const updateSelectedFlowInformation = (payload) => ({
  type: FLOW.UPDATE_SELECTED_FLOW_INFORMATION,
  payload,
});

export const deleteSelectedFlowInformationTags = (payload) => ({
  type: FLOW.DELETE_SELECTED_FLOW_INFORMATION_TAGS,
  payload,
});

export const deleteAllSelectedFlowInformationTags = (payload) => ({
  type: FLOW.DELETE_SELECTED_FLOW_INFORMATION_ALL_TAGS,
  payload,
});

export const deleteFlowNode = (payload) => {
  const { orgId, flowID, nodeId } = payload;

  return {
    type: FLOW.DELETE_FLOW_NODE,
    url: flow(orgId).updateNode(flowID, nodeId),
    payload,
  };
};

export const deleteFlowNodeError = (payload) => ({
  type: FLOW.DELETE_FLOW_NODE_ERROR,
  payload,
});

export const deleteFlowNodeSuccess = (payload) => ({
  type: FLOW.DELETE_FLOW_NODE_SUCCESS,
  payload,
});

export const nodeSizeChange = (payload) => ({
  type: FLOW.NODE_SIZE_CHANGE,
  payload,
});

export const selectStartLevelForCachedRun = (payload) => ({
  type: FLOW.SELECT_START_LEVEL_FOR_CACHED_RUN,
  payload,
});

export const selectPauseLevelForCachedRun = (payload) => ({
  type: FLOW.SELECT_PAUSE_LEVEL_FOR_CACHED_RUN,
  payload,
});

export const setFlowCachedMode = (payload) => ({
  type: FLOW.SET_FLOW_CACHED_MODE,
  payload,
});
