import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { memo, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DropdownMenu } from 'BaseComponents';
import { FlowNodeContext } from 'pages/Flow/Nodes/FlowNode';
import { flowPalette } from 'pages/Flow/HelpFlow/constants';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';


const StyledGridHeader = styled(Grid)({
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 32,
});

const StyledIconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => ({
  height: 32,
  width: 32,
  alignItems: 'center',
  padding: '3px',
  border: '1px solid',
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightColor: `${flowPalette(theme, type).borderColor}`,
  borderBottomColor: `${flowPalette(theme, type).borderColor}`,
  background: `${flowPalette(theme, type).backgroundColor}`,
}));

export const FlowNodeHeader = memo(({
  children, text, Icon, menuItems = [], ...restProps
}) => {
  const isFlowCachedMode = useSelector(state => state.flow.isFlowCachedMode);
  const nodeSize = useSelector((state) => state.flow.nodeSize);
  const { type } = useContext(FlowNodeContext);

  if (nodeSize === 'SM') {
    return <StyledGridHeader item container xs={12} {...restProps} />;
  }

  return (
    <StyledGridHeader item container xs={12} {...restProps}>
      <Grid item xs='auto'>
        <StyledIconBox type={type}>
          {Icon}
        </StyledIconBox>
      </Grid>
      <Grid item xs zeroMinWidth>
        {text ? (
          <Typography noWrap pl={1}>
            {text}
          </Typography>
        ) : children}
      </Grid>
      {!isFlowCachedMode && (
        <Grid item xs='auto'>
          <DropdownMenu icon={<MoreVertIcon />} menuItems={menuItems} />
        </Grid>
      )}
    </StyledGridHeader>
  );
});
