import { DOWNLOAD } from 'redux/actions/Types';


const initialState = {
  isLoading: false,
};

const DownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD.DOWNLOAD_CSV:
    case DOWNLOAD.DOWNLOAD_CSV_POST:
      return { ...state, isLoading: true };
    case DOWNLOAD.DOWNLOAD_CSV_SUCCESS:
      return { ...state, isLoading: false };
    case DOWNLOAD.DOWNLOAD_CSV_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default DownloadReducer;
