import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useContext, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box, TextField, Typography } from '@mui/material';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { HDialog, HDialogContent } from 'BaseComponents';
import { isValidDeltaExponential } from 'pages/Flow/flowUtils';
import { useFetchPrivacyBudgetOverride } from 'api/cleanrooms/useFetchPrivacyBudgetOverride';
import { usePostPrivacyBudgetOverride } from 'api/cleanrooms/usePostPrivacyBudgetOverride';


const AdvancedFlowSettingsDialog = () => {
  const [error, setError] = useState(false);
  const [crPrivacyBudgetOverride, setCrPrivacyBudgetOverride] = useState({
    budgetOverride: '',
    deltaOverride: '',
    maxContributionsOverride: '',
    totalVectorsOverride: '',
  });

  const {
    advancedSetting,
    handleAdvancedSettingClose,
  } = useContext(FlowContext);

  const {
    data,
    isSuccess,
  } = useFetchPrivacyBudgetOverride(advancedSetting?.crID, advancedSetting?.cleanRoomFlowID);
  const {
    isLoading: isSubmitting,
    mutate: submitPrivacyBudgetOverride,
  } = usePostPrivacyBudgetOverride(
    advancedSetting?.crID,
    advancedSetting?.cleanRoomFlowID,
    advancedSetting?.name,
  );

  useEffect(() => {
    if (isSuccess) {
      setCrPrivacyBudgetOverride({
        budgetOverride: data.budgetOverride,
        deltaOverride: data.deltaOverride,
        maxContributionsOverride: data.maxContributionsOverride,
        totalVectorsOverride: data.totalVectorsOverride,
      });
    }
  }, [data, isSuccess]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCrPrivacyBudgetOverride(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeltaChange = (event) => {
    const { value } = event.target;
    setCrPrivacyBudgetOverride(prevState => ({
      ...prevState,
      deltaOverride: value,
    }));

    if (isValidDeltaExponential(value)) {
      setError(false);
    }
    else {
      setError(true);
    }
  };

  const handleSubmit = () => {
    if (!isValidDeltaExponential(crPrivacyBudgetOverride.deltaOverride)) {
      setError(true);
      return;
    }

    submitPrivacyBudgetOverride({
      cleanRoomEntityPrivacyBudgetOverride: {
        entityType: 'FLOW',
        ...crPrivacyBudgetOverride,
      },
    },
    { onSuccess: () => handleAdvancedSettingClose() },
    );
  };

  const onDialogClose = () => {
    setError(false);
    handleAdvancedSettingClose();
  };

  return (
    <HDialog
      fullWidth
      open={Boolean(advancedSetting)}
      title={(
        <Typography variant='h3'>
          Advanced Flow Settings
        </Typography>
      )}
      loading={isSubmitting}
      disabled={error}
      onSubmit={handleSubmit}
      onClose={onDialogClose}
    >
      <HDialogContent>
        <Typography variant='h6' marginBottom='22px'>
          Privacy parameters
        </Typography>
        <Typography variant='body2' marginBottom='22px'>
          Privacy parameters entered here will override any settings in a question.
        </Typography>
        <Typography variant='body2' marginBottom='22px'>
          Creating overrides may affect the privacy guarantee or budget consumed.
        </Typography>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Epsilon (Budget)
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip title='The quantitative privacy guarantee' placement='right'>
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <OutlinedInput
            size='small'
            type='number'
            inputProps={{ min: 0.05 }}
            name='budgetOverride'
            value={crPrivacyBudgetOverride.budgetOverride}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Delta
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip
                title='The probability of information accidentally being leaked'
                placement='right'
              >
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <TextField
            size='small'
            name='deltaOverride'
            value={crPrivacyBudgetOverride.deltaOverride}
            onChange={handleDeltaChange}
            helperText='Enter value in scientific notation (e.g., 1.4E-10) between 1E-10 and 1E-5'
            error={error}
            required
          />
        </Box>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Max Contribution
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip
                title='Maximum number of rows an individual can contribute to a dataset in the clean room'
                placement='right'
              >
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <OutlinedInput
            size='small'
            type='number'
            inputProps={{ min: 1 }}
            name='maxContributionsOverride'
            value={crPrivacyBudgetOverride.maxContributionsOverride}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Total Vectors
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip
                title='Total Vectors is used to divide the privacy budget among the output measures of the question and cannot exceed total number of measures configured for the question'
                placement='right'
              >
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <OutlinedInput
            size='small'
            type='number'
            inputProps={{ min: 0 }}
            name='totalVectorsOverride'
            value={crPrivacyBudgetOverride.totalVectorsOverride}
            onChange={handleInputChange}
          />
        </Box>
      </HDialogContent>
    </HDialog>
  );
};

export default AdvancedFlowSettingsDialog;
