import { FLOW } from 'redux/actions/Types';
import {
  all,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { createConnectNodesWithEdge } from 'redux/saga/FlowsSaga/createConnectNodesWithEdge';
import { createQuestionNode } from 'redux/saga/FlowsSaga/createQuestionNode';
import { deleteQuestionNode } from 'redux/saga/FlowsSaga/deleteQuestionNode';
import { fetchFlowDetails } from 'redux/saga/FlowsSaga/fetchFlowDetails';
import { fetchQuestionDetail } from 'redux/saga/FlowsSaga/fetchQuestionDetail';


export default function* root() {
  yield all([
    takeLatest(FLOW.CREATE_NODE, createQuestionNode),
    takeLatest(FLOW.CONNECT_NODES_WITH_EDGE, createConnectNodesWithEdge),
    takeLatest(FLOW.DELETE_FLOW_NODE, deleteQuestionNode),
    takeLatest(FLOW.FETCH_FLOW_DETAILS, fetchFlowDetails),
    takeEvery(FLOW.UPDATE_FLOW_NODES_QUESTION, fetchQuestionDetail),
  ]);
}
