import React, { createContext, useMemo, useState } from 'react';
import { QUESTION_OUTPUT_TYPES } from 'utils/constants/question.constant';
import { useFetchEdge } from 'api/flows/useFetchEdge';
import { useSelector } from 'react-redux';
import { v4 as uuidGen } from 'uuid';


const edgeMacroDefaultPayload = {
  sourceNodeID: '',
  targetNodeID: '',
  dataSetTypeID: '',
  macros: [],
  ID: '',
};

const isDialogInfoValid = (dialogInfo) => dialogInfo && dialogInfo.data
  && dialogInfo.data.source && dialogInfo.nodesData;

export const NodesEdgeConnectionContext = createContext({});

export const NodesEdgeConnectionProvider = ({ children, crId, flowId }) => {
  const dialogInfo = useSelector(state => state.flow.sidePanel);
  const editMode = dialogInfo?.edgeId;

  const [edgePayload, setEdgePayload] = useState(edgeMacroDefaultPayload);
  const [datasetId, setDatasetId] = useState('');
  const [isValidDialogInfo, setIsValidDialogInfo] = useState(isDialogInfoValid(dialogInfo));

  const { data: { source: sourceID, target: targetID }, nodesData, edgeId } = dialogInfo || {};
  const { data: currentEdge = {}, isSuccess, isLoading } = useFetchEdge(
    { flowId, edgeId });

  const targetNode = nodesData?.find((i) => i.id === targetID);
  const sourceNode = nodesData?.find((i) => i.id === sourceID);

  const { data: { dataTypes: childSourceDataset } } = targetNode || {};

  const {
    data: {
      dimensions: parentDimensions,
      userListOutputs: parentUserListOutputs,
      isUserListQuestion,
      metrics: parentMetrics,
      title: questionName,
      ID: questionId,
    },
  } = sourceNode || {};

  const createMacroMapping = (dataSetTypeFields) => dataSetTypeFields?.map((dt) => ({
    ID: uuidGen(),
    dataSourceField: dt,
    outputFieldID: '',
    questionOutputType: '',
  }));

  React.useEffect(() => {
    if (isLoading) { return; }
    if (isValidDialogInfo && editMode && dialogInfo.data && isSuccess) {
      const {
        questionChainingEdge, parentNodeID, childNodeID,
      } = currentEdge.data.edge;

      const selectedDataset = childSourceDataset?.find(dt => dt.ID
        === questionChainingEdge.childQuestionDataType.ID);

      const macroMapping = createMacroMapping(selectedDataset?.dataSetTypeFields);

      const getFieldID = (apiData) => {
        switch (apiData.questionOutputType) {
          case 'DIMENSION':
            return apiData.questionDimension?.ID || null;
          case 'MEASURE':
            return apiData.questionMetric?.ID || null;
          default:
            return apiData.questionUserListOutput?.ID || null;
        }
      };

      const transformMacro = (macro) => {
        const apiData = questionChainingEdge.questionChainingEdgeMappings.find(
          m => m.questionDataTypeField.ID === macro.dataSourceField.ID);
        if (!apiData) {
          return macro; // Return the original macro if no matching API data is found
        }

        return {
          ...macro,
          outputFieldID: getFieldID(apiData),
          questionOutputType: apiData.questionOutputType,
        };
      };

      const transformedMacro = macroMapping?.map(transformMacro);


      if (selectedDataset) {
        const newEdgePayload = {
          macros: transformedMacro,
          sourceNodeID: parentNodeID,
          targetNodeID: childNodeID,
          dataSetTypeID: questionChainingEdge.childQuestionDataType.ID,
          ID: currentEdge.data.edge.ID,
        };
        setEdgePayload(newEdgePayload);
        setDatasetId(questionChainingEdge.childQuestionDataType.ID);
      }
    }
  }, [childSourceDataset, currentEdge, currentEdge.edge, dialogInfo.data,
    editMode, isLoading, isSuccess, isValidDialogInfo]);

  React.useEffect(() => {
    setIsValidDialogInfo(isDialogInfoValid(dialogInfo));
  }, [dialogInfo]);

  const dimensionMutation = useMemo(() => (parentDimensions ? parentDimensions.map((dt) => (
    { ...dt, questionOutputType: QUESTION_OUTPUT_TYPES.DIMENSION }
  )) : []), [parentDimensions]);

  const metricMutation = useMemo(() => (parentMetrics ? parentMetrics.map((dt) => (
    { ...dt, questionOutputType: QUESTION_OUTPUT_TYPES.MEASURE }
  )) : []), [parentMetrics]);

  const userListOutputMutation = useMemo(() => (parentUserListOutputs
    ? parentUserListOutputs.map((dt) => (
      { ...dt, questionOutputType: QUESTION_OUTPUT_TYPES.USER_LIST }
    )) : []), [parentUserListOutputs]);

  const parentOutput = useMemo(() => (
    isUserListQuestion ? [...userListOutputMutation] : [...dimensionMutation, ...metricMutation]
  ), [isUserListQuestion, dimensionMutation, metricMutation, userListOutputMutation]);

  const onMacroChange = (e, macro, index) => {
    e.preventDefault();

    const newMacros = edgePayload.macros.map((m, i) => (i === index ? {
      ...m,
      outputFieldID: macro.ID,
      questionOutputType: macro.questionOutputType,
    } : m));

    setEdgePayload({
      ...edgePayload,
      macros: newMacros,
    });
  };

  const onDatasetSelect = (dataSourceID) => {
    const selectedDataset = childSourceDataset.find(dt => dt.ID === dataSourceID);

    if (!selectedDataset) {
      return;
    }

    setEdgePayload({
      ...edgePayload,
      dataSetTypeID: dataSourceID,
      macros: createMacroMapping(selectedDataset.dataSetTypeFields),
      sourceNodeID: sourceID,
      targetNodeID: targetID,
    });

    setDatasetId(dataSourceID);
  };

  if (!isValidDialogInfo || !targetNode || !sourceNode) {
    return null;
  }

  return (
    <NodesEdgeConnectionContext.Provider
      value={{
        childSourceDataset,
        crId,
        datasetId,
        dialogInfo,
        edgeId,
        edgePayload,
        flowId,
        onDatasetSelect,
        onMacroChange,
        parentOutput,
        questionId,
        questionName,
        setDatasetId,
        sourceNode,
        targetNode,
        currentEdge,
      }}
    >
      {children}
    </NodesEdgeConnectionContext.Provider>
  );
};
