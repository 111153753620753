import AbcIcon from '@mui/icons-material/Abc';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import NumbersIcon from '@mui/icons-material/Numbers';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import React from 'react';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SortIcon from '@mui/icons-material/Sort';
import { MarkerType } from 'reactflow';


export const NODES_TYPE = {
  question: 'QUESTION',
  transformation: 'transformation',
  activation: 'activation',
  indentity: 'indentity',
  scheduling: 'scheduling',
  disable: 'disable',
  dataset: 'DATASET',
};

export const FLOW_DRAWER_TYPE = {
  PARAMETERS: 'FLOW_PARAMETERS',
  NODE_DETAILS: 'FLOW_NODE',
};

export const RUN_PARAMETER_TYPES = {
  BIGINT: { displayName: 'Big Int', Icon: <b>BIGINT</b> },
  BOOLEAN: { displayName: 'Boolean', Icon: <b>BOOL</b> },
  DATE: { displayName: 'Date', Icon: <CalendarMonthIcon /> },
  DECIMAL: { displayName: 'Decimal', Icon: <b>DECIMAL</b> },
  DOUBLE: { displayName: 'Double', Icon: <ShortTextIcon /> },
  INTEGER: { displayName: 'Integer', Icon: <NumbersIcon /> },
  LONG: { displayName: 'Long', Icon: <SortIcon /> },
  STRING: { displayName: 'String', Icon: <AbcIcon /> },
  TIMESTAMP: { displayName: 'Timestamp', Icon: <QueryBuilderIcon /> },
};

export const EDGES_TYPE = {
  arrow: MarkerType.ArrowClosed,
};

export const EDGES_BASE_STYLE = {
  markerEnd: {
    type: EDGES_TYPE.arrow,
  },
  type: 'buttonedge',
  style: {
    strokeWidth: 3,
  },
};

export const NODES_OBJECT = {
  [NODES_TYPE.question]: {
    Icon: <QuickreplyOutlinedIcon />,
    active: true,
  },
  [NODES_TYPE.transformation]: {
    Icon: <ListAltOutlinedIcon />,
    active: false,
  },
  [NODES_TYPE.activation]: {
    Icon: <CloudUploadOutlinedIcon />,
    active: false,
  },
  [NODES_TYPE.indentity]: {
    Icon: <KeyOutlinedIcon />,
    active: false,
  },
  [NODES_TYPE.scheduling]: {
    Icon: <DataObjectIcon />,
    active: false,
  },
};

export const NODES_CONNECTION_TYPE = {
  QUESTION_TO_QUESTION: 'QUESTION_TO_QUESTION',
  QUESTION_TO_ACTIVATION: 'QUESTION_TO_ACTIVATION',
};

export const FLOW_VIEW_URL = '/flow/view/';

export const PARAMETER_TYPE = {
  RUN_TIME_PARAMETER: 'RUN_TIME_PARAMETER',
  PARTITION_PARAMETER: 'PARTITION_PARAMETER',
  UNKNOWN_PARAMETER: 'UNKNOWN_PARAMETER',
};

export const PARAMETER_DATATYPES = {
  STRING: 'STRING',
  DATE: 'DATE',
  TIMESTAMP: 'TIMESTAMP',
  DECIMAL: 'DECIMAL',
  INTEGER: 'INTEGER',
  DOUBLE: 'DOUBLE',
  LONG: 'LONG',
};
